<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('set.editPhone') }}</div>
			</div>
		</div>
		<!-- <div class="cur_account">
			{{$t('curPhoneNum')}}：{{mobile_code}}-{{mobile}}
		</div> -->
		<div class="now_email" v-if="!!mobile">
			<div class="input_item_icon">
				<img src="@/assets/mycenter/email-1.png">
			</div>
			<div>
				<span class="email">{{ $t('curPhoneNum') }}：</span><span class="email_num">{{ mobile_code }}-{{ mobile
				}}</span>
			</div>
		</div>
		<!-- 旧手机验证 -->
		<div class="input_item" v-if="!!mobile">
			<div class="input_item_icon">
				<img src="@/assets/mycenter/code.png">
			</div>
			<div class="input">
				<input maxlength="6" :placeholder="$t('login.inputCode')" v-model="oldcode" @blur="verifyCode">
			</div>
			<div class="code">
				<div class="code1" @click="getOldCode" v-if="!isGetCode">{{ $t('login.getCode') }}</div>
				<div class="code2" v-else>{{ $t('login.sendAgain') }}：{{ time }}</div>
			</div>
		</div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="@/assets/mycenter/phone.png">
			</div>
			<div class="dropdown">
				<el-dropdown @command="areaCodeClick2">
					<span class="el-dropdown-link">
						{{ new_mobile_code }}<i class="el-icon-caret-bottom el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="item" v-for="(item, index) in areaCodeList" :key="index">
							{{ item }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="input">
				<input :placeholder="$t('set.inputNewPhone')" v-model="new_mobile" :disabled="is_disable" >
			</div>
		</div>
		<!-- 新手机验证 -->
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/code.png">
			</div>
			<div class="input">
				<input maxlength="6" :placeholder="$t('login.inputCode')" v-model="code" :disabled="is_disable">
			</div>
			<div class="code">
				<div class="code1" @click="getCode" v-if="!isGetCode1">{{ $t('login.getCode') }}</div>
				<div class="code2" v-else>{{ $t('login.sendAgain') }}：{{ time }}</div>
			</div>
		</div>
		<div class="btn" @click="submit">
			{{ $t('confirm') }}
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			oldcode: '',//旧验证码
			code: '',//新验证码
			mobile: '',
			mobile_code: '',
			new_mobile: '',//新手机号
			new_mobile_code: '',
			time: 60,
			time1: 60,
			isGetCode: false, //是否获取验证码
			isGetCode1: false, //新
			timer: '', //定时器
			timer1: '',//新定时器
			is_disable: true,
			areaCodeList: [], //区号列表

		}
	},
	mounted() {
		this.getUserInfo()
		this.getAreaCode()
		let userinfo = this.$store.state.userinfo;
		console.log(userinfo, 1234);
		// this.mobile = userinfo.mobile;
		// this.mobile_code = userinfo.mobile_code;

		if (this.mobile == '') {
			this.is_disable = false
		}
	},
	destroyed() {
		clearInterval(this.timer);
	},
	methods: {
		// 获取会员信息
		getUserInfo() {
			return new Promise((resolve, reject) => {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						this.mobile = res.data.mobile
						this.mobile_code = res.data.mobile_code

						let data = '1'
						console.log(!data, 'data');
						console.log(!!this.mobile, 'this.mobile');
					} else if (res.code == -201) {

					} else {
						this.$message.error(res.msg);
					}
					resolve();
				})
			})
		},
		// 获取新手机验证码
		getCode() {
			if (this.new_mobile_code == '') {
				this.$message.error(this.$t('chooseAreaCode'))
				return
			}
			let data = {
				type: 5,
				is_test: 1,
				mobile: this.new_mobile,
				mobile_code: this.new_mobile_code,
			}

			this.$http.getCode(data).then(res => {
				if (res.code == 1) {
					this.$message.success(this.$t('sendSuccess'));
					this.$message.success(res.data.code);
					console.log(res.data.code, '验证码');
					this.isGetCode1 = true;
					this.timer = setInterval(() => {
						if (this.time == 1) {
							clearInterval(this.timer);
							this.time = 60;
							this.isGetCode1 = false;
						}
						this.time--;
					}, 1000);
				} else {
					this.$message.error(res.msg);
				}
			}).catch((v) => {

			});
		},

		// 获取旧手机验证码
		getOldCode() {
			let data = {
				type: 4,
				is_test: 1,
				mobile: this.mobile,
				mobile_code: this.mobile_code,
			}

			this.$http.getCode(data).then(res => {
				if (res.code == 1) {
					this.$message.success(this.$t('sendSuccess'));
					this.$message.success(res.data.code);
					console.log(res.data.code, '验证码');
					this.isGetCode = true;
					this.timer1 = setInterval(() => {
						if (this.time1 == 1) {
							clearInterval(this.timer1);
							this.time1 = 60;
							this.isGetCode = false;
						}
						this.time--;
					}, 1000);
				} else {
					this.$message.error(res.msg);
				}
			}).catch((v) => {

			});
		},

		/* 校验验证码 */
		verifyCode() {
			if (!this.oldcode) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}
			this.$http.verifyCode({
				mobile: this.mobile,
				code: this.oldcode,
				type: 4,
				mobile_code: this.mobile_code,
			}).then(res => {
				if (res.code == 1) {
					this.is_disable = false
					this.$message.success('请输入新手机号')
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		// 下一步
		nextStep() {
			if (!this.code) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}

			this.$http.verifyCode({
				mobile: this.mobile,
				mobile_code: this.mobile_code,
				code: this.code,
				type: 7,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.$router.push('/editBindingPhone2')
				} else {
					this.$message.error(res.msg);
				}
			})
			// 2022-09-15号修改
			this.$router.push('/editBindingPhone2')
		},

		// 获取区号
		getAreaCode() {
			return new Promise((resolve, reject) => {
				this.$http.getAreaCode().then(res => {
					if (res.code == 1) {
						this.areaCodeList = res.data;
						if (!this.mobile) {
							this.mobile_code = res.data[0];
						}
					} else {
						this.$message.error(res.data.msg);
					}
					resolve();
				}).catch((v) => {
					console.log(v);
				});
			})
		},

		// 区号点击
		areaCodeClick2(command) {
			this.new_mobile_code = command;
		},

		// 提交
		submit() {
			this.$http.bindingPhone(
				{
					mobile: this.new_mobile,
					code: this.code,
					mobile_code: this.new_mobile_code
				}
			).then(res => {
				// if(this.mobile_code + this.mobile == this.new_mobile_code + this.new_mobile){
				// 	this.$message.error('新手机号与旧手机号相同')
				// 	return
				// }
				if (res.code == 1) {
					this.$message.success('修改成功')
					this.this.getUserInfo()
					setTimeout(() => {
						this.clearInput()
					}, 3000);
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		// 清空输入框
		clearInput() {

			this.oldcode = '',//旧验证码
				this.code = '',//新验证码
				this.mobile = '',
				this.mobile_code = '',
				this.new_mobile = '',//新手机号
				this.new_mobile_code = '',
				this.isGetCode = false, //是否获取验证码
				this.isGetCode1 = false, //新

				this.is_disable = true,
				clearInterval(this.timer);
			clearInterval(this.timer1);
			this.time = 60;
			this.time1 = 60;
		},
	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	// width: 926px;
}

.login_top {
	display: flex;
	padding: 32px 40px;
	border-bottom: 1px solid #F3F5F6;

	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
	}
}

.now_email {
	margin: 32px 40px;
	padding-left: 24px;
	display: flex;
	justify-content: left;
	align-items: center;
	width: 800px;
	height: 56px;
	background: repeating-linear-gradient(to right, rgba(243, 152, 0, 0.2), rgba(243, 152, 0, 0));

	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.email {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333FF;
		margin-left: 8px;
	}

	.email_num {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #F39800FF;
	}
}

.cur_account {
	color: #333333;
	font-size: 16px;
	text-align: left;
	padding: 48px 32px;
}

.dropdown {
	margin-left: 24px;

	&:hover {
		cursor: pointer;
	}
}

.input_item {
	margin-left: 40px;
	margin-bottom: 32px;
	text-align: left;
	display: flex;
	width: 800px;
	padding: 14px 24px;
	border: 1px solid #DEDEDE;
	font-size: 16px;
	position: relative;


	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.input {
		padding-left: 16px;

		input {
			width: 400px;
			border: none;
			outline: none;
			font-size: 16px;
		}
	}

	.code {
		position: absolute;
		top: 13px;
		right: 24px;
		color: #4177DD;
		font-size: 16px;

		&:hover {
			cursor: pointer;
		}

	}

	.show_icon {
		position: absolute;
		top: 13px;
		right: 24px;

		img {
			width: 18px;
			height: 18px;
		}
	}
}

.btn {
	margin-left: 40px;
	text-align: center;
	line-height: 48px;
	background: #F39800;
	opacity: 1;
	width: 300px;
	height: 48px;
	border-radius: 4px;
	font-size: 16px;
	color: #FFFFFF;

	&:hover {
		cursor: pointer;
	}
}

.input_item .input input {
	background: #fff;
}
</style>
